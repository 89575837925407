import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import MaterialTable from "material-table";
import axios from "axios";
import Select from "react-select";
let empList = [

];


function PschyAll() {

  var [convert, setConvert] = useState("Waiting for Data...");

  const [data, setData] = useState(empList);



  if(convert!==" " && data.length<=0){


  axios.get("http://www.platinummedapp.com/psych/all").then(function (response) {


 console.log("clinicList");
 console.log(response.data);

  setConvert(" ");

  setData(response.data)
  
})

 .catch(function (error) {
    console.log(error);
 });



}

  const columns = [
    { title: "Id", field: "_id",    hidden: true, },
    { title: "State", field: "state"},
    { title: "State Acronym", field: "stateNick"},
    { title: "Closest Clinic", field: "closestClinic"},

   

  ];

  return (
    <div className="text-center">



      {convert==="Waiting for Zip Master Data..."?<h3>Waiting for State Data...</h3>:
      <MaterialTable


        title=""
        data={data}
        columns={columns}
        editable={{
          onRowAdd: newData =>{
            console.log("Add Request response");
            console.log(newData);
            axios.post("http://www.platinummedapp.com/psych/add",newData).then(function (response) {
              console.log('000000000response.data');
              console.log(response.data);
             setData([...data, response.data]); 
            //  setData(updatedRows)
            });
         return new Promise((resolve, reject) => {
       

            console.log(newData);
              setTimeout(() => {
                   

                  resolve();
              }, 4000);
          })},
          
          onRowDelete: oldData =>{
          axios.delete("http://www.platinummedapp.com/psych/"+oldData._id).then(function (response) {
            console.log(response);
        
            
        });
         return new Promise((resolve, reject) => {
              setTimeout(() => {
                  const dataDelete = [...data];
                  const index = oldData.tableData.id;
               
                  dataDelete.splice(index, 1);
                  setData([...dataDelete]);

                  resolve();
              }, 1000);
          })},
          
       
          onRowUpdate: (updatedRow, oldRow) =>{
            console.log(oldRow);
            axios.post("http://www.platinummedapp.com/psych/update/"+oldRow._id,updatedRow).then(function (response) {
              console.log(response);
            
          });
           return new Promise((resolve, reject) => {
              const index = oldRow.tableData.id;
              const updatedRows = [...data];
              updatedRows[index] = updatedRow;
             
        

            setTimeout(() => {
              setData(updatedRows);
              resolve();
            }, 1000);



        

              
            })},
      
      }}
        options={{
          headerStyle: {
            backgroundColor: '#00a0da',
            color: '#FFF',
            fontWeight:'bold',
            fontSize:'16px'
    
          },
          actionsColumnIndex: -1,
          addRowPosition: "first",
          maxBodyHeight:'600px',
          paging:true,
          pageSize:50,       // make initial page size
          emptyRowsWhenPaging: false,   // To avoid of having empty rows
          pageSizeOptions:[30,50,100],    // rows selection options
        }}
      />
}
    </div>
  );
}

export default PschyAll;
