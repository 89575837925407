
import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";

const Facilities = () => {
  const [facilities, setFacilities] = useState([]);

  useEffect(() => {
    fetchFacilitiesData();
  }, []);

  const fetchFacilitiesData = async () => {
    try {
      const response = await axios.get("http://www.platinummedapp.com/facility");
      setFacilities(response.data);
    } catch (error) {
      console.error("Error fetching facilities data:", error);
    }
  };

  const columns = [
    { title: "Closest Clinic", field: "closestClinic" },
    { title: "City", field: "city" },
    { title: "State", field: "state" },
    { title: "Zip", field: "zip" },
  ];

  return (
    <div>
      <MaterialTable
        title=" "
        data={facilities}
        columns={columns}
        editable={{
          onRowAdd: async (newData) => {
            try {
              const response = await axios.post("http://www.platinummedapp.com/facility", newData);
              setFacilities([...facilities, response.data]);
            } catch (error) {
              console.error("Error adding facility:", error);
            }
          },
          onRowUpdate: async (newData, oldData) => {
            try {
              const response = await axios.put(
                `http://www.platinummedapp.com/facility/${oldData._id}`,
                newData
              );
              const updatedFacilities = [...facilities];
              const index = updatedFacilities.findIndex(
                (facility) => facility._id === oldData._id
              );
              updatedFacilities[index] = response.data;
              setFacilities(updatedFacilities);
            } catch (error) {
              console.error("Error updating facility:", error);
            }
          },
          onRowDelete: async (oldData) => {
            try {
              await axios.delete(`http://www.platinummedapp.com/facility/${oldData._id}`);
              const updatedFacilities = facilities.filter(
                (facility) => facility._id !== oldData._id
              );
              setFacilities(updatedFacilities);
            } catch (error) {
              console.error("Error deleting facility:", error);
            }
          },
        }}
        options={{
          headerStyle: {
            backgroundColor: '#00a0da',
            color: '#FFF',
            fontWeight:'bold',
            fontSize:'16px'
    
          },
          actionsColumnIndex: -1,
          addRowPosition: "first",
          maxBodyHeight:'600px',
          paging:true,
          pageSize:50,       // make initial page size
          emptyRowsWhenPaging: false,   // To avoid of having empty rows
          pageSizeOptions:[30,50,100],    // rows selection options
        }}
      />
    </div>
  );
};

export default Facilities;
